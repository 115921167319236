import React from 'react'
import Compare from '../containers/Compare'
import { graphql } from 'gatsby'

const CompareQuebecPage = (props) => (
    <Compare
        cta="CLIQUEZ ICI POUR TROUVER VOTRE AVOCAT"
        noindex={false}
        canonical={'com'}
        {...props}
    />
)

export default CompareQuebecPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
